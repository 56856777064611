import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '../components/common/link';
import { create } from '../helpers/bem';
import { resolve } from '../helpers/urls';

import './404.scss';

const bem = create('page-error');

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div className={bem()}>
      <h1 className={bem('headline')}>{t('Page not found')}</h1>
      <Link className={bem('subline')} to={resolve('index')}>{t('Back to homepage')}</Link>
    </div>
  );
};

export default NotFoundPage;
